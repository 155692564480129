import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';

import Driver, {DriverIdentityDocuments} from 'core/entities/Driver/types';

import trimAndReduceSpaces from 'utils/trimAndReduceSpaces';

function replaceEntityObjectWithId(value) {
    if (isPlainObject(value)) {
        return value.id;
    }

    return value;
}

const getOptionValue = (item: unknown) => {
    if (isObject(item) && 'value' in item) {
        return item.value;
    }

    return item;
};

type DriverIdentityDocumentsFormValues = Omit<DriverIdentityDocuments, 'type'> & {
    type: {label: string; value: string};
};
const transformDriverDocuments = (
    driverDocuments?: DriverIdentityDocumentsFormValues[] | null,
): DriverIdentityDocuments[] | null =>
    (driverDocuments || [])
        .filter((doc) => doc.type)
        .map((doc) => ({
            ...doc,
            visa_type: doc.visa_type || null,
            issue_date: doc.issue_date || null,
            expiration_date: doc.expiration_date || null,
            type: getOptionValue(doc.type) as string | null,
        }));

export function formatDriverFormFields(fields) {
    const data = {
        ...fields,
        expiration: fields.expiration || null,
        hazmat_since: fields.hazmat_since || null,
        hazmat_exp: fields.hazmat_exp || null,
        tsa_since: fields.tsa_since || null,
        tsa_exp: fields.tsa_exp || null,
        twic_since: fields.twic_since || null,
        twic_exp: fields.twic_exp || null,
        language: getOptionValue(fields.language),
        adr_country: getOptionValue(fields.adr_country),
        full_name: trimAndReduceSpaces(fields.full_name),
        carrier: replaceEntityObjectWithId(fields.carrier),
        licence_state: getOptionValue(fields.licence_state),
        hired_by: replaceEntityObjectWithId(fields.hired_by),
        licence_country: getOptionValue(fields.licence_country),
        country_of_birth: getOptionValue(fields.country_of_birth),
        owner: (!fields.is_deleted && fields?.owner_id) || replaceEntityObjectWithId(fields.owner),
        drivers_documents: transformDriverDocuments(fields.drivers_documents),
    };

    delete data.status;

    return data;
}

export function checkIfUniqueFieldsChanged(newDriver: Driver, currentDriver: Driver): boolean {
    return currentDriver.full_name !== newDriver.full_name || currentDriver.mobile_phone !== newDriver.mobile_phone;
}

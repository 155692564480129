import {ALLOWED_IMAGE_MIME_TYPES} from 'components/ui/Files/FileUpload/constants';

const validateProfilePhotoUpload = (file?: File) => {
    if (!file) {
        return false;
    }

    const {type, name} = file;
    const [extension] = name.split('.').reverse();

    return ALLOWED_IMAGE_MIME_TYPES[type] && ALLOWED_IMAGE_MIME_TYPES[type].includes(`.${extension}`);
};

export default validateProfilePhotoUpload;

export const DRIVER_RECEIVED = 'DRIVER_RECEIVED';
export const DRIVER_RESTORE_ACCOUNT = 'DRIVER_RESTORE_ACCOUNT';
export const DRIVER_ARCHIVED = 'DRIVER_ARCHIVED';
export const DRIVER_RESTORED = 'DRIVER_RESTORED';
export const DRIVER_GENERAL_NOTE_RECEIVED = 'DRIVER_GENERAL_NOTE_RECEIVED';
export const DRIVER_GENERAL_NOTE_DELETED = 'DRIVER_GENERAL_NOTE_DELETED';
export const FORM_TAB_CHANGED = 'DRIVER_FORM_TAB_CHANGED';
export const CLEAR_DRIVER_STATE = 'DRIVER_CLEAR_DRIVER_STATE';
export const CLEAR_DRIVER_MODALS_STATE = 'DRIVER_CLEAR_DRIVER_MODALS_STATE';
export const SET_DRIVER_WORKING_WITH_OWNER = 'DRIVER_PAGE_SET_WORKING_WITH_OWNER';
export const SET_DRIVER_FIELDS_COPIED_FROM_OWNER = 'DRIVER_PAGE_FIELDS_COPIED_FROM_OWNER';
export const DRIVERS_RECEIVED = 'DRIVERS_COMPONENT_ITEMS_RECEIVED';
export const DRIVERS_SET_PAGINATION = 'DRIVERS_SET_PAGINATION_PARAMS';
export const DRIVERS_SET_SEARCH_PARAMS = 'DRIVERS_SET_SEARCH_PARAMS';
export const DRIVERS_SET_LIST_TAB = 'DRIVERS_SET_LIST_TAB';
export const DRIVERS_TOGGLE_SEARCH_FORM = 'DRIVERS_COMPONENT_TOGGLE_SEARCH_FORM';
export const DRIVERS_TOGGLE_MODAL = 'DRIVERS_COMPONENT_TOGGLE_MODAL';
export const DRIVERS_MESSAGE_SEND_SUCCESS = 'DRIVERS_COMPONENT_MESSAGE_SEND_SUCCESS';
export const DRIVERS_MESSAGE_SEND_ERROR = 'DRIVERS_COMPONENT_MESSAGE_SEND_ERROR';
export const DRIVERS_CLEAR_MESSAGE_RESULTS = 'DRIVERS_COMPONENT_CLEAR_MESSAGE_RESULTS';
export const CLEAR_STATE = 'DRIVERS_CLEAR_STATE';
export const SHOW_MODAL = 'DRIVERS_COMPONENT_SHOW_MODAL';
export const CLOSE_MODAL = 'DRIVERS_COMPONENT_CLOSE_MODAL';
export const DRIVERS_CLEAR_LIST_ITEMS = 'DRIVERS_CLEAR_LIST_ITEMS';
export const SET_IS_RESTORE_DRIVER_ACCOUNT = 'SET_IS_RESTORE_DRIVER_ACCOUNT';
export const DRIVERS_SET_ACTIVE_LIST_TAB = 'DRIVERS_SET_ACTIVE_LIST_TAB';

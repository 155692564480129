import {isEmpty, isString, toLower, trim} from 'lodash';

const USER_REGIONS = {
    NA: 'na',
    EU: 'eu',
};

export const getServerIDsByRegion = (params: {
    HR_SERVER_ID_EU: string | null;
    HR_SERVER_ID_NA: string | null;

    region: string | null;

    serverIds: string[];
}): string[] => {
    const {serverIds, HR_SERVER_ID_EU, HR_SERVER_ID_NA, region} = params;

    if (isEmpty(serverIds) && isEmpty(isString(region) ? trim(region) : '')) {
        return [];
    }

    const isUserFromEU = toLower(USER_REGIONS.EU) === toLower(region || '');
    const isUserFromNA = toLower(USER_REGIONS.NA) === toLower(region || '');

    const hrServerIdEU = isUserFromEU ? [HR_SERVER_ID_EU] : [];
    const hrServerIdNA = isUserFromNA ? [HR_SERVER_ID_NA] : [];

    return [...(serverIds || []), ...hrServerIdEU, ...hrServerIdNA].filter(isString);
};

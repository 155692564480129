import {fetchOwner} from 'core/gateways/OwnerApiGateway/requests';
import {TruckApiGateway} from 'core/useCases/Truck/types';

import {postGeneralNote} from 'services/restapi/generalNotes';
import {deleteTruckFile} from 'services/restapi/index';

import {ENTITY_NAME_TRUCKS} from 'utils/data/entityNames';
import postGroupedFiles from 'utils/files/postGroupedFiles';

import {
    getListTrucksOnMap,
    getTruckByNumber,
    getTruckFiles,
    getTruckNotes,
    postTruck,
    putTruck,
    restoreTruck,
    searchTrucksByIDsRequest,
} from './requests';

const truckApiGateway: TruckApiGateway = {
    getTruck: (truckNumber) => {
        return getTruckByNumber(truckNumber).then((response) => response.data);
    },
    getTruckOwner: (truck) => {
        const ownerID = truck.owner.id;
        return fetchOwner(ownerID).then((response) => response.data);
    },
    getTruckNotes: (truckID) => {
        return getTruckNotes(truckID);
    },
    getTruckFiles: (truckID) => {
        return getTruckFiles(truckID);
    },
    createTruck: (truckData: any) => {
        return postTruck(truckData).then((response) => response.data);
    },
    updateTruck: (changedTruck) => {
        return putTruck(changedTruck.id, changedTruck).then((response) => response.data);
    },
    addTruckGeneralNote: (params) => {
        const noteParams = {
            entity_id: params.truckID,
            entity_name: ENTITY_NAME_TRUCKS,
            text: params.note,
        };
        return postGeneralNote(noteParams).then(({data}) => data);
    },
    addTruckFiles: (params) => {
        const {truckID, files} = params;
        return postGroupedFiles(truckID, ENTITY_NAME_TRUCKS, files);
    },
    removeTruckFiles: (fileIds: string[]) => {
        const promises = fileIds.map((fileID) => {
            return deleteTruckFile(fileID);
        });
        return Promise.allSettled(promises);
    },
    searchListTrucksOnMap: (searchParams) => {
        return getListTrucksOnMap(searchParams);
    },
    restoreTruck: (truck) => {
        return restoreTruck(truck);
    },
    searchTrucksByIDsRequest,
};

export default truckApiGateway;

import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';

import Truck from 'core/entities/Truck/types';

import {ListState} from 'pages/TrucksNearby/redux/reducers/list';

export const handleReducerByReceivedSSEData = (params: {state: ListState; trucks: Truck[]}) => {
    const {state, trucks} = params;

    if (isEmpty(state.items) || isEmpty(trucks)) {
        return state;
    }

    const updatedTrucksById = keyBy(trucks, 'id');
    const updatedItems = state.items.map((item) => {
        const updatedTruck = updatedTrucksById[item.id];

        if (updatedTruck) {
            return {
                ...item,
                ...updatedTruck,
                isOwn: updatedTruck.isOwn || item.isOwn,
                distance: updatedTruck.distance || item.distance,
                companiesNumber: updatedTruck.companiesNumber || item.companiesNumber,
                generalTotalNotes: updatedTruck.generalTotalNotes || item.generalTotalNotes,
            };
        }

        return item;
    });

    return {
        ...state,
        items: updatedItems,
    };
};

import isEmpty from 'lodash/isEmpty';
import {ThunkAction} from 'redux-thunk';

import {AppState} from 'store';
import * as appActions from 'store/actions';
import * as registryActions from 'store/reducers/registry/actions';
import {selectTravelOrderDefaultPresets} from 'store/reducers/registry/selectors';

import {getLoadNumber} from 'core/entities/Load/modules/loadNumber';
import {transformBrokerUpdateDataToRequestBody} from 'core/entities/LocationEvents/mappers/brokerUpdate';
import {LocationEventData} from 'core/entities/LocationEvents/types';
import {getTravelOrderNumber} from 'core/entities/TravelOrder/modules/common/getTravelOrderNumber';
import {isTravelOrderHasCarrier} from 'core/entities/TravelOrder/modules/travelOrderCarrier';
import {isTravelOrderNeedReDispatch} from 'core/entities/TravelOrder/modules/travelOrderDispatch';
import {getLoadFromTravelOrder, getLoadNotesFromTravelOrder} from 'core/entities/TravelOrder/modules/travelOrderLoad';
import {isTravelOrderPlanned} from 'core/entities/TravelOrder/modules/travelOrderProgress/isTravelOrderPlanned';
import {isTravelOrderDryRun} from 'core/entities/TravelOrder/modules/travelOrderStatus';
import {getTravelOrderStopActions} from 'core/entities/TravelOrder/modules/travelOrderStops';
import {getTruckFromTheTravelOrder, isTravelOrderHasTruck} from 'core/entities/TravelOrder/modules/travelOrderTruck';
import * as truckModules from 'core/entities/TravelOrder/modules/travelOrderTruck';
import TravelOrder from 'core/entities/TravelOrder/types';
import locationEventApiGateway from 'core/gateways/LocationEventApiGateway';
import * as toRequests from 'core/gateways/TravelOrderApiGateway/requests/indexNew';

import {BrokerUpdateSendFormValues} from 'components/common/LocationEvents/modals/BrokerUpdateSendModal/types';
import {AddCheckCallFormValues} from 'components/common/LocationEvents/modals/CheckCallModal/types';
import {locationEventsModalNames} from 'components/common/LocationEvents/modals/modalMap';
import {closeAll, openModal} from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import {travelOrderModalNames} from 'pages/TravelOrders/components/common/modals/modalMap';
import {modalNames} from 'pages/TravelOrders/constants/travel-order';
import * as types from 'pages/TravelOrders/redux/actionTypes/modal';
import {getCurrentTravelOrder} from 'pages/TravelOrders/redux/selectors';

import * as listActions from '../list';
import {travelOrderActionCreators} from '../travelOrder';

type ThunkActionTypes = ThunkAction<void, AppState, unknown, any>;
type TOStopID = TravelOrder['toStops'][number]['id'];

export const showModal = (payload: {modalName: string; modalData: any}) =>
    ({type: types.TRIP_MONITOR_SHOW_MODAL, payload} as const);

export const closeModal = () => ({type: types.TRIP_MONITOR_HIDE_MODAL});

export const showAddCheckCallModal = (params: {travelOrder?: TravelOrder; truckNumber?: string}): ThunkActionTypes => (
    dispatch,
) => {
    const {travelOrder, truckNumber} = params;

    const travelOrderNumber = getTravelOrderNumber(travelOrder);

    if (!travelOrderNumber) {
        return null;
    }

    const onAddEvent = (createFormValue: AddCheckCallFormValues) => {
        dispatch(listActions.addCheckCall({travelOrder, createFormValue}));
        dispatch(closeAll());
    };

    dispatch(
        openModal({
            modalName: locationEventsModalNames.checkCall,
            data: {travelOrder, truckNumber},
            handlers: {onAddEvent},
        }),
    );
};

export const showSendBrokerUpdateModal = ({
    eventID,
    travelOrderNumber,
}: {
    eventID?: string;
    travelOrderNumber?: number;
}) => async (dispatch) => {
    if (!eventID || !travelOrderNumber) {
        return null;
    }

    try {
        dispatch(appActions.showLoader());

        const response = await locationEventApiGateway.getBrokerUpdate(eventID, travelOrderNumber);

        const onUpdate = (brokerUpdate: BrokerUpdateSendFormValues) => {
            const sendBrokerUpdateData = transformBrokerUpdateDataToRequestBody(brokerUpdate);
            dispatch(listActions.sendBrokerUpdate({eventID, travelOrderNumber, sendBrokerUpdateData}));
            dispatch(closeAll());
        };

        dispatch(
            openModal({
                modalName: locationEventsModalNames.brokerUpdateModal,
                data: response,
                handlers: {onUpdate},
            }),
        );
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const showViewLocationEventBrokerUpdateModal = ({
    event,
    travelOrder,
}: {
    event?: LocationEventData | null;
    travelOrder?: TravelOrder;
}): ThunkActionTypes => (dispatch) => {
    if (!event || !travelOrder) {
        return null;
    }

    dispatch(
        openModal({
            modalName: locationEventsModalNames.viewBrokerUpdate,
            data: {
                travelOrder,
                event,
            },
        }),
    );
};

export const showPlannedLoadsModal = (params: {travelOrder?: TravelOrder | null}): ThunkActionTypes => (dispatch) => {
    const {travelOrder} = params;

    if (!travelOrder) {
        return null;
    }

    const truck = getTruckFromTheTravelOrder(travelOrder);

    const {plannedTravelOrders} = commonModalNames;

    dispatch(openModal({modalName: plannedTravelOrders, data: {truck}}));
};

export const showTimerSettingsModal = (params: {travelOrder: TravelOrder}) => async (dispatch, getState) => {
    const {travelOrder} = params;

    const state = getState();

    const defaultPresets = selectTravelOrderDefaultPresets(state);

    if (isEmpty(defaultPresets.brokerUpdateText)) {
        await dispatch(registryActions.fetchDefaultTravelOrderPresets());
    }

    const onSetEventTimer = (timerSettings) => {
        dispatch(listActions.setEventTimer({timerSettings, travelOrderNumber: travelOrder.number}));
        dispatch(closeAll());
    };

    dispatch(
        openModal({
            modalName: travelOrderModalNames.timerSettingsModal,
            handlers: {onSetEventTimer},
            data: {travelOrder},
        }),
    );
};

export const showAutoCheckCallInfoModal = ({travelOrder}: {travelOrder: TravelOrder}) => (dispatch) => {
    dispatch(
        openModal({modalName: locationEventsModalNames.autoCheckCallInfoModal, data: {travelOrder}, handlers: {}}),
    );
};

const showConfirmDispatchReDispatchModalForThePlanedTO = (): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const truck = getTruckFromTheTravelOrder(travelOrder);
    const toNumber = getTravelOrderNumber(travelOrder);

    try {
        dispatch(appActions.showLoader());

        const params = {toNumber, truckNumber: truck?.number};
        const {data: location} = await toRequests.fetchLastLocationOfThePreviousTravelOrder(params);

        dispatch(showModal({modalName: modalNames.confirmDispatchReDispatchModal, modalData: {location}}));
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const showConfirmDispatchReDispatchModalForTheUnPlanedTO = (): ThunkActionTypes => (dispatch) => {
    dispatch(showModal({modalName: modalNames.confirmDispatchReDispatchModal, modalData: undefined}));
};

export const showConfirmDispatchReDispatchModal = (): ThunkActionTypes => (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());

    const isTOPlanned = isTravelOrderPlanned(travelOrder);

    return isTOPlanned
        ? dispatch(showConfirmDispatchReDispatchModalForThePlanedTO())
        : dispatch(showConfirmDispatchReDispatchModalForTheUnPlanedTO());
};

export const showAddTravelOrderNotesModal = (params: {travelOrder?: TravelOrder | null; fromList?: boolean}) => (
    dispatch,
) => {
    const {travelOrder, fromList = false} = params;

    if (!travelOrder) {
        return null;
    }

    const {addNotesModal} = commonModalNames;

    const modalHandlers = {
        onSuccessAddedNote: (newTravelOrder: TravelOrder) => {
            if (fromList) {
                dispatch(
                    listActions.tripMonitorActionCreators.receivedGeneralNoteTravelOrder({travelOrder: newTravelOrder}),
                );
            } else {
                dispatch(travelOrderActionCreators.travelOrderReceived(newTravelOrder));
            }
        },
    };

    dispatch(openModal({handlers: modalHandlers, modalName: addNotesModal, data: {travelOrder}}));
};

export const showAddLoadGeneralNoteModal = (params: {
    travelOrder?: TravelOrder | null;
    permissionUpdateLoad: boolean;
}) => (dispatch) => {
    const {travelOrder, permissionUpdateLoad} = params;

    if (!travelOrder) {
        return null;
    }

    const load = getLoadFromTravelOrder(travelOrder);
    const loadNumber = getLoadNumber(load);

    if (!loadNumber) {
        return null;
    }

    const {generalNotes} = commonModalNames;

    const modalTitle = `Load# ${loadNumber} Notes`;

    const modalHandlers = {
        onSubmit: (note: {text: string}) =>
            dispatch(listActions.addLoadNoteToTravelOrder({newNoteText: note.text, loadNumber})),
    };

    const modalSelectors = {
        notesSelector: (currentState) => {
            const currentTravelOrdersList = currentState.tripMonitor.list.items;

            const updatedTravelOrder = currentTravelOrdersList.find((item) => item.number === travelOrder.number);

            return getLoadNotesFromTravelOrder(updatedTravelOrder).map((note) => ({
                dispatcher: note?.createdBy || {},
                is_important: note.isImportant,
                post_date: note.createdAt,
                text: note.note,
                id: note.id,
            }));
        },
    };

    dispatch(
        openModal({
            modalName: generalNotes,
            data: {title: modalTitle, addNoteDisabled: !permissionUpdateLoad},
            handlers: modalHandlers,
            selectors: modalSelectors,
        }),
    );
};

export const showEmailAttachmentsModal = (params): ThunkActionTypes => async (dispatch, getState) => {
    const {stopID} = params;

    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);

    try {
        dispatch(appActions.showLoader());

        const {data} = await toRequests.getLatestTravelOrderEmailSubjectRequest(toNumber);
        const modalData = {subject: data, stopID};

        dispatch(showModal({modalName: modalNames.emailAttachmentsModal, modalData}));
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const showSuccessfullySentEmailAttachmentsModal = (): ThunkActionTypes => (dispatch) => {
    const data = {
        message: 'The email has been successfully sent.',
        title: 'Successful Email',
        rightButtonTitle: 'Ok',
        bodyType: 'Success',
        buttonType: 'success',
    };

    dispatch(openModal({modalName: commonModalNames.informationModal, data}));
};

export const showColorSettingsModal = (currentSettings, onSaveSettings) => (dispatch) => {
    const showColorSettingModalAction = openModal({
        modalName: travelOrderModalNames.colorSettingsModal,
        data: {colorSettings: currentSettings},
        handlers: {
            onSaveSettings: (settings) => {
                onSaveSettings(settings);
                dispatch(closeAll());
            },
        },
    });

    dispatch(showColorSettingModalAction);
};

const showCancelPlannedTOModal = (): ThunkActionTypes => (dispatch) => {
    dispatch(showModal({modalName: modalNames.cancelPlannedTravelOrderModal, modalData: undefined}));
};

const showCancelNotPlannedTOWithPlannedTOModal = (travelOrder: TravelOrder): ThunkActionTypes => async (dispatch) => {
    const toTruck = truckModules.getTruckFromTheTravelOrder(travelOrder);
    const toNumber = getTravelOrderNumber(travelOrder);

    if (!toNumber || !toTruck) {
        return;
    }

    try {
        dispatch(appActions.showLoader());

        const {data: nextPlannedTOData} = await toRequests.fetchNextPlannedTOByTruckIdAndTravelOrderId({
            toNumber,
            truckId: toTruck.id,
        });

        dispatch(
            showModal({
                modalName: modalNames.cancelNotPlannedTOWithPlannedTOModal,
                modalData: {nextPlannedTOData},
            }),
        );
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const showCancelNotPlannedTOWithoutPlannedTOModal = (): ThunkActionTypes => (dispatch) => {
    dispatch(showModal({modalName: modalNames.cancelNotPlannedTOWithoutPlannedTOModal, modalData: undefined}));
};

export const showTravelOrderCancelModal = (): ThunkActionTypes => (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());

    if (!travelOrder) {
        return;
    }

    const isTOPlanned = isTravelOrderPlanned(travelOrder);
    const isTOHasCarrier = isTravelOrderHasCarrier(travelOrder);
    const isConfirmedDryRun = isTravelOrderDryRun(travelOrder);
    const isTOHasTruck = truckModules.isTravelOrderHasTruck(travelOrder);
    const isTOHasNextPlannedTO = truckModules.isTravelOrderHasNextPlannedTO(travelOrder);

    if (isTOPlanned || isTOHasCarrier || isConfirmedDryRun) {
        dispatch(showCancelPlannedTOModal());
        return;
    }

    if (isTOHasTruck && isTOHasNextPlannedTO) {
        dispatch(showCancelNotPlannedTOWithPlannedTOModal(travelOrder));
        return;
    }

    if (isTOHasTruck && !isTOHasNextPlannedTO) {
        dispatch(showCancelNotPlannedTOWithoutPlannedTOModal());
    }
};

const showCancelAddBOLModal = ({stopID}: {stopID: TOStopID}): ThunkActionTypes => (dispatch) => {
    dispatch(showModal({modalName: modalNames.cancelAddBOLModal, modalData: {stopID}}));
};

const showCancelCheckInModal = ({stopID}: {stopID: TOStopID}): ThunkActionTypes => (dispatch) => {
    dispatch(showModal({modalName: modalNames.cancelCheckInModal, modalData: {stopID}}));
};

const showCancelCheckOutModal = ({stopID}: {stopID: TOStopID}): ThunkActionTypes => (dispatch) => {
    dispatch(showModal({modalName: modalNames.cancelCheckOutModal, modalData: {stopID}}));
};

const showCancelSignPODModal = ({stopID}: {stopID: TOStopID}): ThunkActionTypes => (dispatch) => {
    dispatch(showModal({modalName: modalNames.cancelSignPODModal, modalData: {stopID}}));
};

const showCancelFinishModal = (): ThunkActionTypes => (dispatch) => {
    dispatch(showModal({modalName: modalNames.cancelFinishModal, modalData: undefined}));
};

export const showCancelStopModal = (params: {stopID: TOStopID}): ThunkActionTypes => (dispatch, getState) => {
    const {stopID} = params;

    if (!stopID) {
        return;
    }

    const travelOrder = getCurrentTravelOrder(getState());
    const stopActions = getTravelOrderStopActions({travelOrder, stopID});

    const {
        allowCancelCheckOut,
        allowCancelSignPOD,
        allowCancelCheckIn,
        allowCancelAddBOL,
        allowCancelFinish,
    } = stopActions;

    if (allowCancelCheckIn) {
        dispatch(showCancelCheckInModal({stopID}));
        return;
    }

    if (allowCancelCheckOut) {
        dispatch(showCancelCheckOutModal({stopID}));
        return;
    }

    if (allowCancelSignPOD) {
        dispatch(showCancelSignPODModal({stopID}));
        return;
    }

    if (allowCancelAddBOL) {
        dispatch(showCancelAddBOLModal({stopID}));
        return;
    }

    if (allowCancelFinish) {
        dispatch(showCancelFinishModal());
    }
};

export const showSuccessfulFinishTravelOrderModal = (): ThunkActionTypes => (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());

    const isTOHasCarrier = isTravelOrderHasCarrier(travelOrder);
    const isTOHasTruck = isTravelOrderHasTruck(travelOrder);

    if (isTOHasCarrier) {
        dispatch(showModal({modalName: modalNames.successfulFinishCarrierModal, modalData: undefined}));
        return;
    }

    if (isTOHasTruck) {
        dispatch(showModal({modalName: modalNames.successfulFinishTruckModal, modalData: undefined}));
    }
};

export const showNotSignedPODModal = (): ThunkActionTypes => (dispatch) => {
    dispatch(showModal({modalName: modalNames.notSignedBolModal, modalData: undefined}));
};

export const showConfirmDryRunModalWithNextPlannedTO = (): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());

    if (!travelOrder) {
        return;
    }

    const toTruck = truckModules.getTruckFromTheTravelOrder(travelOrder);
    const toNumber = getTravelOrderNumber(travelOrder);

    if (!toNumber || !toTruck) {
        return;
    }

    try {
        dispatch(appActions.showLoader());

        const {data: nextPlannedTOData} = await toRequests.fetchNextPlannedTOByTruckIdAndTravelOrderId({
            toNumber,
            truckId: toTruck.id,
        });

        dispatch(
            showModal({
                modalName: modalNames.confirmDryRunModal,
                modalData: {nextPlannedTOData: nextPlannedTOData || null},
            }),
        );
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const showConfirmDryRunModal = (): ThunkActionTypes => (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());

    if (!travelOrder) {
        return;
    }

    const isTOHasNextPlannedTO = truckModules.isTravelOrderHasNextPlannedTO(travelOrder);

    if (isTOHasNextPlannedTO) {
        dispatch(showConfirmDryRunModalWithNextPlannedTO());
        return;
    }

    dispatch(
        showModal({
            modalName: modalNames.confirmDryRunModal,
            modalData: undefined,
        }),
    );
};

const showDispatchModal = (): ThunkActionTypes => (dispatch) => {
    dispatch(showModal({modalName: modalNames.dispatchReDispatchModal, modalData: undefined}));
};

const showReDispatchModal = (): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);

    try {
        dispatch(appActions.showLoader());

        const {data: reDispatchTO} = await toRequests.fetchRedispatchTravelOrder(`${toNumber}`);

        dispatch(showModal({modalName: modalNames.dispatchReDispatchModal, modalData: {reDispatchTO}}));
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const showDispatchReDispatchModal = (): ThunkActionTypes => (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());

    const isTONeedReDispatch = isTravelOrderNeedReDispatch(travelOrder);

    if (isTONeedReDispatch) {
        dispatch(showReDispatchModal());
        return;
    }

    if (!isTONeedReDispatch) {
        dispatch(showDispatchModal());
    }
};

import {ServerItem} from 'core/entities/Owner/types';

import useCurrentTruckState from 'pages/Trucks/hooks/useCurrentTruckState';
import {TruckCreateUpdateFormValues} from 'pages/Trucks/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import useSelectCompaniesState from './useSelectCompaniesState';

const getName = getTypeFieldNameFactory<TruckCreateUpdateFormValues>();

type Params = {company?: ServerItem; index?: number; fromInitial?: boolean; truckForm: string};

type SyncItem = {label: string; value: number; hasActiveLoad: boolean} | null;

type UseSyncTableRows = {
    companiesForSelectionAll: SyncItem[];
    companiesForClearAll: SyncItem[];
    isDisallowTurnOff: boolean;
    isNotAvailable: boolean;
    isDisabled: boolean;
};

const useSyncTableRows = (params: Params): UseSyncTableRows => {
    const {company, fromInitial, truckForm} = params;

    const selectedOwner = useGetReduxFormValue(truckForm, getName('owner'));
    const {companies, initialCompanies} = useSelectCompaniesState();
    const {truck} = useCurrentTruckState();

    const sourceCompanies = fromInitial ? initialCompanies : companies;

    const ownerCompanies = selectedOwner?.value?.companies || [];
    const ownerCompany = ownerCompanies.find((item) => item.id === company?.id);

    // check is truck has active load on the current company
    const isCurrentHasActiveLoad = (truck?.companies || []).find((item) => item.id === company?.id)?.hasActiveLoad;

    const companiesForSelectionAll = (sourceCompanies || []).map((sourceCompany) => {
        const foundCompany = ownerCompanies.find((item) => item?.id === sourceCompany?.id);
        const {hasActiveLoad} = (truck?.companies || []).find((item) => item.id === foundCompany?.id) || {};

        const {company_name, id} = sourceCompany || {};

        if (foundCompany) {
            return {hasActiveLoad: Boolean(hasActiveLoad), label: company_name, value: id};
        }

        return null;
    });

    const companiesForClearAll = (sourceCompanies || []).map((sourceCompany) => {
        const foundCompany = ownerCompanies.find((item) => item?.id === sourceCompany?.id);
        const {hasActiveLoad} = (truck?.companies || []).find((item) => item.id === foundCompany?.id) || {};

        const {company_name, id} = sourceCompany || {};

        if (foundCompany && hasActiveLoad) {
            return {hasActiveLoad: Boolean(hasActiveLoad), label: company_name, value: id};
        }

        return null;
    });

    return {
        isDisabled: !ownerCompany || Boolean(isCurrentHasActiveLoad),
        isDisallowTurnOff: Boolean(isCurrentHasActiveLoad),
        isNotAvailable: !ownerCompany,
        companiesForSelectionAll,
        companiesForClearAll,
    };
};

export default useSyncTableRows;

import {useDispatch} from 'react-redux';

import * as actions from '../actions/current';

const useDriverActions = () => {
    const dispatch = useDispatch();

    return {
        fetchDriver: (driverId: string) => dispatch(actions.fetchDriver(driverId)),
        setIsRestoreDriverAccount: (isRestoreAccount: boolean) =>
            dispatch(actions.setIsRestoreDriverAccount(isRestoreAccount)),
        clearState: () => dispatch(actions.clearState()),
        clearModalsState: () => dispatch(actions.clearModalsState()),
    };
};

export default useDriverActions;

import {useSelector} from 'react-redux';

import * as selectors from '../redux/selectors';

type UseCurrentTruckState = {
    insuranceExpiration: ReturnType<typeof selectors.selectCurrentTruckInsuranceExpiration>;
    activeTab: ReturnType<typeof selectors.selectTruckUpdateActiveTab>;
    drivers: ReturnType<typeof selectors.selectCurrentTruckDrivers>;
    number: ReturnType<typeof selectors.selectCurrentTruckNumber>;
    status: ReturnType<typeof selectors.selectCurrentTruckStatus>;
    truck: ReturnType<typeof selectors.selectCurrentTruck>;
};

const useCurrentTruckState = (): UseCurrentTruckState => ({
    insuranceExpiration: useSelector(selectors.selectCurrentTruckInsuranceExpiration),
    activeTab: useSelector(selectors.selectTruckUpdateActiveTab),
    drivers: useSelector(selectors.selectCurrentTruckDrivers),
    number: useSelector(selectors.selectCurrentTruckNumber),
    status: useSelector(selectors.selectCurrentTruckStatus),
    truck: useSelector(selectors.selectCurrentTruck),
});

export default useCurrentTruckState;
